import { frontendURL } from '../../../helper/URLHelper.js';
import BotLearningView from './components/BotLearningView.vue';
import { BOT_LEARNING_ROUTES } from './constants.js';

export const routes = [
  {
    path: frontendURL(`accounts/:accountId/${BOT_LEARNING_ROUTES.MAIN.PATH}`),
    name: BOT_LEARNING_ROUTES.MAIN.NAME,
    roles: ['administrator'],
    component: BotLearningView,
  },
  {
    path: frontendURL(
      `accounts/:accountId/${BOT_LEARNING_ROUTES.MAIN.PATH}/:tabId/${BOT_LEARNING_ROUTES.TAB_ITEMS.PATH}`
    ),
    name: BOT_LEARNING_ROUTES.TAB_ITEMS.NAME,
    roles: ['administrator'],
    component: BotLearningView,
    props: route => {
      return { tabId: route.params.tabId };
    },
  },
];
