<!-- eslint-disable vue/no-mutating-props -->
<template>
  <woot-modal size="medium" :show.sync="show" :on-close="onCancel">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header
        :header-title="$t(`${actionText}.TITLE`)"
        :header-content="$t(`${actionText}.DESC`)"
      />
      <bot-learning-item-form
        :tab-id="tabId"
        :item-id="itemId"
        :only-answer="onlyAnswer"
        @cancel="onCancel"
        @create="onCreate"
      />
    </div>
  </woot-modal>
</template>

<script>
import BotLearningItemForm from './BotLearningItemForm.vue';

export default {
  components: {
    BotLearningItemForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tabId: {
      type: String,
      default: null,
    },
    itemId: {
      type: Number,
      default: null,
    },
    onlyAnswer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actionText() {
      if (this.onlyAnswer) return 'BOT_LEARNING_PAGE.EDIT_ANSWER';
      return this.itemId
        ? 'BOT_LEARNING_PAGE.EDIT'
        : 'BOT_LEARNING_PAGE.CREATE';
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onCreate() {
      this.$emit('create');
    },
  },
};
</script>
