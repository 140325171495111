<template>
  <section
    class="bg-white dark:bg-slate-900 flex-1 h-full overflow-hidden -mt-1"
  >
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 7.125rem)"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
    />
    <empty-state
      v-if="!isLoading && !records.length"
      :title="$t('BOT_LEARNING_PAGE.LIST.NO_BOT_LEARNING')"
    />
    <woot-loading-state
      v-if="isLoading"
      class="text-xs flex-grow"
      :message="$t('BOT_LEARNING_PAGE.LIST.LOADING_MESSAGE')"
    />
  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';

import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import rtlMixin from 'shared/mixins/rtlMixin';

export default {
  components: {
    EmptyState,
    VeTable,
    Spinner,
  },
  mixins: [rtlMixin],
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableData() {
      if (this.isLoading) {
        return [];
      }
      return this.records;
    },
    columns() {
      return [
        {
          field: 'id',
          key: 'id',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.ID'),
          align: this.isRTLView ? 'right' : 'left',
          width: '80px',
        },
        {
          field: 'title',
          key: 'title',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.TITLE'),
          align: this.isRTLView ? 'right' : 'left',
          width: '19%',
        },
        {
          field: 'answer',
          key: 'answer',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.ANSWER'),
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            return (
              <span class="whitespace-nowrap block overflow-hidden text-ellipsis">
                {row.answer}
              </span>
            );
          },
          width: '23%',
        },
        {
          field: 'question',
          key: 'question',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.SAMPLE_QUESTION'),
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            return (
              <span class="whitespace-nowrap block overflow-hidden text-ellipsis">
                {row.question}
              </span>
            );
          },
          width: '23%',
        },
        {
          field: 'question_count',
          key: 'question_count',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.QUESTION_COUNT'),
          align: 'center',
        },
        {
          field: 'disabled',
          key: 'disabled',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.STATUS'),
          align: 'center',
          renderBodyCell: ({ row }) => {
            return row.disabled
              ? this.$t('BOT_LEARNING_PAGE.LIST.TABLE_BODY.DISABLE')
              : this.$t('BOT_LEARNING_PAGE.LIST.TABLE_BODY.ACTIVE');
          },
        },
        {
          field: 'published',
          key: 'published',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.PUBLISHED'),
          align: 'center',
          renderBodyCell: ({ row }) => {
            if (row.inprogress)
              return this.$t('BOT_LEARNING_PAGE.LIST.TABLE_BODY.PUBLISHING');
            return row.published
              ? this.$t('BOT_LEARNING_PAGE.LIST.TABLE_BODY.PUBLISHED')
              : this.$t('BOT_LEARNING_PAGE.LIST.TABLE_BODY.UNPUBLISHED');
          },
        },
        {
          field: 'edit',
          key: 'edit',
          title: '',
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) =>
            row.inprogress ? (
              <spinner size="tiny" color-scheme="primary" />
            ) : (
              <div class="flex items-center justify-center gap-1">
                <woot-button
                  size="tiny"
                  color-scheme="primary"
                  onClick={() => this.openEditItem(row.id, true)}
                >
                  {this.$t('BOT_LEARNING_PAGE.EDIT_ANSWER.TITLE')}
                </woot-button>
                <woot-button
                  size="tiny"
                  variant="clear"
                  color-scheme="secondary"
                  icon="edit"
                  onClick={() => this.openEditItem(row.id, false)}
                />
              </div>
            ),
        },
      ];
    },
  },
  methods: {
    openEditItem(itemId, onlyAnswer) {
      this.$emit('open-edit-item', { itemId, onlyAnswer });
    },
  },
};
</script>
