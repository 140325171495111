<template>
  <div
    class="bg-white dark:bg-slate-800 py-6 px-2 w-[220px] h-[180px] border border-slate-100 dark:border-slate-700 rounded-md shadow-md flex flex-col gap-6 items-center"
  >
    <span class="text-woot-900">
      {{ title }}
    </span>
    <div class="flex-grow flex flex-col items-center justify-between">
      <div class="text-center" v-html="value" />
      <woot-button
        v-if="btnText && !loading"
        :disabled="disabled"
        @click="onClick"
      >
        {{ btnText }}
      </woot-button>
      <spinner v-if="loading" color-scheme="primary" />
    </div>
  </div>
</template>

<script>
import Spinner from 'shared/components/Spinner.vue';

export default {
  components: {
    Spinner,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
