<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t(`BOT_LEARNING_PAGE.TABS.MODAL.${actionText}.HEADER`)"
      :header-content="$t(`BOT_LEARNING_PAGE.TABS.MODAL.${actionText}.DESC`)"
    />
    <form class="mx-0 flex flex-wrap w-full" @submit.prevent="submit()">
      <woot-input
        ref="titleInput"
        v-model.trim="title"
        :class="{ error: $v.title.$error }"
        class="w-full"
        :label="$t(`BOT_LEARNING_PAGE.TABS.MODAL.${actionText}.FORM.NAME`)"
        :placeholder="
          $t(`BOT_LEARNING_PAGE.TABS.MODAL.${actionText}.FORM.PLACEHOLDER`)
        "
        :error="
          $v.title.$error
            ? $t(`BOT_LEARNING_PAGE.TABS.MODAL.${actionText}.FORM.ERROR`)
            : ''
        "
        @input="$v.title.$touch"
      />
      <div class="flex justify-end items-center py-2 px-0 gap-2 w-full">
        <woot-button
          :is-disabled="$v.title.$invalid || uiFlags.isCreatingTab"
          :is-loading="uiFlags.isCreatingTab"
        >
          {{ $t(`BOT_LEARNING_PAGE.TABS.MODAL.${actionText}.FORM.ADD_BUTTON`) }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="handleClose">
          {{
            $t(`BOT_LEARNING_PAGE.TABS.MODAL.${actionText}.FORM.CANCEL_BUTTON`)
          }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { DuplicateTabException } from 'shared/helpers/CustomErrors';

export default {
  mixins: [alertMixin],
  props: {
    prefill: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      title: this.prefill ? this.prefill.title : '',
    };
  },
  validations: {
    title: { required },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'botLearnings/getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
    actionText() {
      return this.prefill ? 'EDIT' : 'ADD';
    },
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      this.$refs.titleInput.$el.focus();
    },
    handleClose() {
      this.onClose();
    },
    onClose(editedTab = null) {
      this.$emit('close', editedTab);
    },
    async submit() {
      const action = `botLearnings/${
        this.prefill?.tabId ? 'editTab' : 'createTab'
      }`;
      const method = this.prefill?.tabId ? 'EDIT' : 'ADD';
      try {
        const newTab = await this.$store.dispatch(action, {
          title: this.title,
          accountId: this.accountId,
          tabId: this.prefill?.tabId,
        });
        this.showAlert(
          this.$t(`BOT_LEARNING_PAGE.TABS.MODAL.${method}.SUCCESS_MESSAGE`)
        );
        if (this.prefill?.tabId) {
          this.onClose(newTab);
        } else {
          this.onClose();
          this.$router.push({ path: newTab.toState });
        }
      } catch (error) {
        const errorMessage =
          error instanceof DuplicateTabException
            ? this.$t(error.message)
            : this.$t(`BOT_LEARNING_PAGE.TABS.MODAL.${method}.ERROR_MESSAGE`);
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>
