import moment from 'jalali-moment';

export default {
  methods: {
    messageStamp(time, dateFormat = 'h:mm a') {
      const unixTime = moment.unix(time).locale('fa');
      return unixTime.format(dateFormat);
    },
    messageTimestamp(time, dateFormat = 'MMM D, yyyy') {
      const messageTime = moment.unix(time);
      const now = moment();
      const messageDate = messageTime.format(dateFormat);
      if (!messageTime.isSame(now, 'year')) {
        return messageTime.format('LLL D yyyy, h:mm a');
      }
      return messageDate;
    },
    dynamicTime(time) {
      const unixTime = moment.unix(time);
      return unixTime.locale('fa').fromNow();
    },
    dateFormat(time, dateFormat = 'MMM D, yyyy') {
      const unixTime = moment.unix(time);
      return unixTime.format(dateFormat);
    },
    shortTimestamp(time, withAgo = false) {
      const suffix = withAgo ? ' پیش' : '';
      const timeMappings = {
        'less than a minute ago': 'اکنون',
        'a minute ago': `۱دقیقه${suffix}`,
        'an hour ago': `۱ساعت${suffix}`,
        'a day ago': `۱روز${suffix}`,
        'a month ago': `۱ماه${suffix}`,
        'a year ago': `۱سال${suffix}`,
      };

      if (timeMappings[time]) {
        return timeMappings[time];
      }

      const convertToShortTime = time
        .replace(/about|over|almost/g, '')
        .replace(' minute ago', `دقیقه${suffix}`)
        .replace(' minutes ago', `دقیقه${suffix}`)
        .replace(' hour ago', `ساعت${suffix}`)
        .replace(' hours ago', `ساعت${suffix}`)
        .replace(' day ago', `روز${suffix}`)
        .replace(' days ago', `روز${suffix}`)
        .replace(' month ago', `ماه${suffix}`)
        .replace(' months ago', `ماه${suffix}`)
        .replace(' year ago', `سال${suffix}`)
        .replace(' years ago', `سال${suffix}`);
      return convertToShortTime;
    },
  },
};
