<template>
  <div class="flex-grow">
    <woot-loading-state
      v-if="uiFlags.isFetchingTabs"
      class="text-xs flex-grow"
    />
    <div
      v-else-if="!dashboardSelected"
      class="w-full flex flex-col h-full"
      :class="wrapClass"
    >
      <bot-learning-header
        :header-title="header"
        :has-item-records="hasItemRecords"
        :search-query="searchQuery"
        @on-toggle-create-item="onToggleCreateItem"
        @open-edit-tab="openEditTabModal"
        @open-delete-tab="openDeleteTabModal"
        @on-input-search="onInputSearch"
        @on-search-submit="onSearchSubmit"
      />
      <bot-learning-items-table
        :records="itemRecords"
        :is-loading="uiFlags.isFetchingItems"
        @open-edit-item="onToggleEditItem"
      />
      <table-footer
        class="border-t border-slate-75 dark:border-slate-700/50"
        :current-page="Number(meta.currentPage)"
        :total-count="meta.count"
        :page-size="pageSize"
        @page-change="onPageChange"
      />
      <add-bot-learning-item
        :tab-id="tabId"
        :show="showCreateItemModal"
        @cancel="onToggleCreateItem"
        @create="onNewItemCreated"
      />
      <edit-bot-learning-item
        :item-id="editableItemId"
        :tab-id="tabId"
        :show="showEditItemModal"
        :only-answer="editAnswer"
        @cancel="onToggleEditItem"
      />
    </div>
    <div v-else class="flex flex-col w-full h-full">
      <bot-learning-header
        :header-title="header"
        :editable="false"
        @on-toggle-create-item="onToggleCreateItem"
      />
      <div class="p-4 flex gap-4">
        <report-card
          :title="$t('BOT_LEARNING_PAGE.DASHBOARD.LAST_PUBLISH')"
          :value="lastPublishDate"
        />
        <report-card
          :title="$t('BOT_LEARNING_PAGE.DASHBOARD.NOT_PUBLISHED_COUNT')"
          :value="pendingCount"
        />
        <report-card
          :title="$t('BOT_LEARNING_PAGE.DASHBOARD.STATUS')"
          :value="publishStatus"
          :btn-text="$t('BOT_LEARNING_PAGE.DASHBOARD.PUBLISH_BTN')"
          :disabled="!dashboard.can_publish"
          :loading="dashboard.status === 'running'"
          @click="publish"
        />
      </div>
      <div class="mt-8 p-4">
        <publications-table />
      </div>
    </div>
    <woot-modal
      :show.sync="showDeleteTabModal"
      :on-close="hideDeleteTabModal"
      size="max-w-xs"
    >
      <delete-tab-modal
        :tab-id="tabId"
        :has-item-records="hasItemRecords"
        @close="hideDeleteTabModal"
        @delete="onTabDeleted"
      />
    </woot-modal>
    <woot-modal
      :show.sync="showEditTabModal"
      :on-close="hideEditTabModal"
      :close-on-backdrop-click="false"
    >
      <edit-tab-modal :prefill="tabInfo" @close="hideEditTabModal" />
    </woot-modal>
    <woot-modal
      :show.sync="showPublishModal"
      :on-close="hidePublishModal"
      size="max-w-xs"
    >
      <publish-modal @close="hidePublishModal" />
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BotLearningItemsTable from './BotLearningItemsTable.vue';
import BotLearningHeader from './Header.vue';
import TableFooter from 'dashboard/components/widgets/TableFooter.vue';
import AddBotLearningItem from './AddBotLearningItem.vue';
import EditBotLearningItem from './AddBotLearningItem.vue';
import EditTabModal from './AddNewTab.vue';
import DeleteTabModal from './DeleteTab.vue';
import PublishModal from './PublishModal.vue';
import PublicationsTable from './PublicationsTable.vue';
import { BOT_LEARNING_ROUTES } from '../constants.js';
import rtlMixin from 'shared/mixins/rtlMixin';
import moment from 'jalali-moment';
import ReportCard from './ReportCard.vue';

const DEFAULT_PAGE = 1;
const PAGE_SIZE = 20;

export default {
  components: {
    BotLearningItemsTable,
    BotLearningHeader,
    TableFooter,
    AddBotLearningItem,
    EditBotLearningItem,
    EditTabModal,
    DeleteTabModal,
    ReportCard,
    PublishModal,
    PublicationsTable,
  },
  mixins: [rtlMixin],
  props: {
    tabId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      header: '',
      editableItemId: null,
      showCreateItemModal: false,
      showEditItemModal: false,
      showEditTabModal: false,
      showDeleteTabModal: false,
      searchQuery: '',
      showPublishModal: false,
      timeout: null,
      editAnswer: false,
    };
  },
  computed: {
    ...mapGetters({
      itemRecords: 'botLearnings/getBotLearningItems',
      botLearningTabs: 'botLearnings/getBotLearningTabs',
      uiFlags: 'botLearnings/getUIFlags',
      meta: 'botLearnings/getMeta',
      dashboard: 'botLearnings/getDashboard',
    }),
    pageParameter() {
      const selectedPageNumber = Number(this.$route.query?.page);
      return !Number.isNaN(selectedPageNumber) &&
        selectedPageNumber >= DEFAULT_PAGE
        ? selectedPageNumber
        : DEFAULT_PAGE;
    },
    wrapClass() {
      return this.showContactViewPane ? 'w-[75%]' : 'w-full';
    },
    hasItemRecords() {
      return this.itemRecords.length > 0;
    },
    pageSize() {
      return PAGE_SIZE;
    },
    tabInfo() {
      if (this.tabId) {
        return {
          tabId: this.tabId,
          title: this.header,
        };
      }
      return null;
    },
    dashboardSelected() {
      return !this.tabId;
    },
    lastPublishDate() {
      if (this.dashboard?.published_at) {
        return moment(this.dashboard?.published_at)
          .locale('fa')
          .format('yyyy/MM/DD hh:mm')
          .split(' ')
          .join('<br />');
      }
      return '-';
    },
    publishStatus() {
      const s = (this.dashboard?.status ?? 'idle').toUpperCase();
      return this.$t(`BOT_LEARNING_PAGE.DASHBOARD.STATUS_${s}`, {
        count: this.dashboard?.inprogress_count ?? 0,
      });
    },
    pendingCount() {
      return this.dashboard?.pending_count?.toString() ?? '0';
    },
  },
  watch: {
    tabId() {
      this.fetchBotLearnings(this.pageParameter);
      this.setHeader();
    },
    botLearningTabs: {
      handler() {
        this.setHeader();
      },
      once: true,
    },
    dashboard() {
      if (this.dashboard?.status === 'running') {
        this.timeout = setTimeout(() => {
          this.$store.dispatch('botLearnings/getDashboard');
        }, 60000);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  mounted() {
    this.fetchBotLearnings(this.pageParameter);
  },
  methods: {
    fetchBotLearnings(page) {
      if (this.tabId) {
        const requestParams = {
          tabId: this.tabId,
          page: page,
          page_size: this.pageSize,
          q: this.searchQuery,
        };
        this.$store.dispatch('botLearnings/getItems', requestParams);
      } else {
        this.$store.dispatch('botLearnings/getDashboard');
      }
    },
    onPageChange(page) {
      this.fetchBotLearnings(page);
    },
    setHeader(title = '') {
      if (title) {
        this.header = title;
      } else if (this.tabId) {
        const tab = this.botLearningTabs.find(
          c => c.id.toString() === this.tabId
        );
        if (tab) {
          this.header = tab.title;
        }
      } else {
        this.header = this.$t('BOT_LEARNING_PAGE.DASHBOARD.TITLE');
      }
    },
    onTabDeleted() {
      this.$router.replace({ name: BOT_LEARNING_ROUTES.MAIN.NAME });
    },
    onToggleCreateItem() {
      if (this.tabId) {
        this.showCreateItemModal = !this.showCreateItemModal;
      } else {
        this.openEditTabModal();
      }
    },
    onToggleEditItem(a) {
      this.showEditItemModal = !this.showEditItemModal;
      if (a) {
        this.editableItemId = a.itemId;
        this.editAnswer = a.onlyAnswer;
      }
    },
    openEditTabModal() {
      this.showEditTabModal = true;
    },
    hideEditTabModal(editedTab) {
      if (editedTab) {
        this.setHeader(editedTab);
      }
      this.showEditTabModal = false;
    },
    openDeleteTabModal() {
      if (!this.hasItemRecords) this.showDeleteTabModal = true;
    },
    hideDeleteTabModal() {
      this.showDeleteTabModal = false;
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllItems = !!this.searchQuery && newQuery === '';
      this.searchQuery = newQuery;
      if (refetchAllItems) {
        this.fetchBotLearnings(DEFAULT_PAGE);
      }
    },
    onSearchSubmit() {
      if (this.searchQuery) {
        this.fetchBotLearnings(DEFAULT_PAGE);
      }
    },
    onNewItemCreated() {
      this.searchQuery = '';
      this.fetchBotLearnings(DEFAULT_PAGE);
    },
    publish() {
      this.showPublishModal = true;
    },
    hidePublishModal() {
      this.showPublishModal = false;
    },
  },
};
</script>
