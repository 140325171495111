<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('BOT_LEARNING_PAGE.PUBLISH.MODAL.HEADER')"
      :header-content="$t('BOT_LEARNING_PAGE.PUBLISH.MODAL.DESC')"
    />
    <div class="flex justify-end items-center pt-4 px-8 pb-8 gap-2 w-full">
      <woot-button :is-loading="uiFlags.isPublishing" @click="submit">
        {{ $t('BOT_LEARNING_PAGE.PUBLISH.MODAL.CONFIRM_BUTTON') }}
      </woot-button>
      <woot-button class="button clear" @click.prevent="onClose">
        {{ $t('BOT_LEARNING_PAGE.PUBLISH.MODAL.CANCEL_BUTTON') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';

export default {
  mixins: [alertMixin],
  props: {},
  computed: {
    ...mapGetters({
      uiFlags: 'botLearnings/getUIFlags',
    }),
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async submit() {
      try {
        await this.$store.dispatch('botLearnings/publishItems');
        this.showAlert(
          this.$t('BOT_LEARNING_PAGE.PUBLISH.MODAL.SUCCESS_MESSAGE')
        );
        this.onClose();
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t('BOT_LEARNING_PAGE.PUBLISH.MODAL.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>
