/* global axios */
import ApiClient from './ApiClient';

class BotLearningDashboardAPI extends ApiClient {
  constructor() {
    super('bot_learning_publications', {
      apiVersion: 'v2',
      accountScoped: true,
    });
  }

  get() {
    return axios.get(this.url);
  }

  publish() {
    return super.create();
  }
}

export default new BotLearningDashboardAPI();
