<template>
  <ve-table
    v-if="tableData.length"
    :fixed-header="true"
    :columns="columns"
    :table-data="tableData"
    :border-around="false"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { VeTable } from 'vue-easytable';

import rtlMixin from 'shared/mixins/rtlMixin';
import moment from 'jalali-moment';

export default {
  components: {
    VeTable,
  },
  mixins: [rtlMixin],
  computed: {
    ...mapGetters({
      dashboard: 'botLearnings/getDashboard',
    }),
    tableData() {
      return this.dashboard?.items ?? [];
    },
    columns() {
      return [
        {
          field: 'id',
          key: 'id',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.ID'),
          align: 'center',
          width: '80px',
        },
        {
          field: 'request_id',
          key: 'request_id',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.REQUEST_ID'),
          align: 'center',
        },
        {
          field: 'collection_name',
          key: 'collection_name',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.COLLECTION'),
          align: 'center',
        },
        {
          field: 'created_at',
          key: 'created_at',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.CREATED_AT'),
          align: 'center',
          renderBodyCell: ({ row }) => {
            return (
              <div dir="ltr">
                {moment(row.created_at)
                  .locale('fa')
                  .format('yyyy/MM/DD - hh:mm')}
              </div>
            );
          },
        },
        {
          field: 'updated_at',
          key: 'updated_at',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.UPDATED_AT'),
          align: 'center',
          renderBodyCell: ({ row }) => {
            if (!row.updated_at || row.status === 'running') return '-';
            return (
              <div dir="ltr">
                {moment(row.updated_at)
                  .locale('fa')
                  .format('yyyy/MM/DD - hh:mm')}
              </div>
            );
          },
        },
        {
          field: 'status',
          key: 'status',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.STATUS'),
          align: 'center',
          renderBodyCell: ({ row }) => {
            return this.$t(
              `BOT_LEARNING_PAGE.LIST.TABLE_BODY.STATUS_${row.status.toUpperCase()}`
            );
          },
        },
      ];
    },
  },
};
</script>
