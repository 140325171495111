<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('BOT_LEARNING_PAGE.TABS.MODAL.DELETE.HEADER')"
      :header-content="
        !hasItemRecords
          ? $t('BOT_LEARNING_PAGE.TABS.MODAL.DELETE.DESC')
          : $t('BOT_LEARNING_PAGE.TABS.MODAL.DELETE.DESC_IMPOSIBLE')
      "
    />
    <div class="flex justify-end items-center pt-4 px-8 pb-8 gap-2 w-full">
      <woot-button
        :is-disabled="uiFlags.isDeletingTab || hasItemRecords"
        :is-loading="uiFlags.isDeletingTab"
        @click="deleteTab"
      >
        {{ $t('BOT_LEARNING_PAGE.TABS.MODAL.DELETE.REMOVE_BUTTON') }}
      </woot-button>
      <woot-button class="button clear" @click.prevent="onClose">
        {{ $t('BOT_LEARNING_PAGE.TABS.MODAL.DELETE.CANCEL_BUTTON') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';

export default {
  mixins: [alertMixin],
  props: {
    tabId: {
      type: String,
      default: '',
    },
    hasItemRecords: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'botLearnings/getUIFlags',
    }),
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onDelete() {
      this.$emit('delete');
    },
    async deleteTab() {
      if (this.hasItemRecords) return;
      try {
        await this.$store.dispatch('botLearnings/deleteTab', this.tabId);
        this.showAlert(
          this.$t('BOT_LEARNING_PAGE.TABS.MODAL.DELETE.SUCCESS_MESSAGE')
        );
        this.onDelete();
        this.onClose();
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t('BOT_LEARNING_PAGE.TABS.MODAL.DELETE.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>
