import types from 'dashboard/store/mutation-types';
import BotLearningTabsAPI from 'dashboard/api/botLearningTabs';
import BotLearningItemAPI from 'dashboard/api/botLearningItems';
import BotLearningDashboardAPI from 'dashboard/api/botLearningDashboard';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { DuplicateTabException } from 'shared/helpers/CustomErrors.js';

const createTabUrl = (accountId, tab) => {
  return Object.assign(tab, {
    toState: frontendURL(`accounts/${accountId}/bot-learning/${tab.id}/list`),
  });
};

export const actions = {
  getTabs: async ({ commit }, data) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingTabs: true });
    try {
      const {
        data: { items },
      } = await BotLearningTabsAPI.get();
      items.map(tab => createTabUrl(data.accountId, tab));
      items.unshift({
        id: 'main',
        label: 'BOT_LEARNING.DASHBOARD',
        toState: frontendURL(`accounts/${data.accountId}/bot-learning`),
      });
      commit(types.SET_BOT_LEARNING_TABS, items);
    } finally {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingTabs: false });
    }
  },

  getDashboard: async ({ commit }) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingDashboard: true });
    try {
      const { data } = await BotLearningDashboardAPI.get();
      commit(types.SET_BOT_LEARNING_DASHBOARD, data);
    } finally {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingDashboard: false });
    }
  },

  createTab: async function createTab({ commit }, data) {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingTab: true });
    try {
      const response = await BotLearningTabsAPI.create({
        title: data.title,
      });
      const newTab = createTabUrl(data.accountId, response.data);
      commit(types.ADD_BOT_LEARNING_TAB, newTab);
      return newTab;
    } catch (error) {
      if (error.response?.status === 409) {
        throw new DuplicateTabException();
      } else {
        throw new Error();
      }
    } finally {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingTab: false });
    }
  },

  deleteTab: async ({ commit }, id) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isDeletingTab: true });
    try {
      await BotLearningTabsAPI.delete(id);
      commit(types.DELETE_BOT_LEARNING_TAB, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isDeletingTab: false });
    }
  },

  editTab: async ({ commit }, data) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingTab: true });
    try {
      const {
        data: { id, title },
      } = await BotLearningTabsAPI.update(data.tabId, { title: data.title });
      commit(types.UPDATE_BOT_LEARNING_TAB, {
        id,
        title,
      });
      return title;
    } catch (error) {
      if (error.response?.status === 409) {
        throw new DuplicateTabException();
      } else {
        throw new Error(error);
      }
    } finally {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingTab: false });
    }
  },

  getItems: async ({ commit }, data) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingItems: true });
    try {
      const {
        data: { items, total_count },
      } = await BotLearningItemAPI.get(data);
      commit(types.SET_BOT_LEARNING_ITEMS, items);
      commit(types.SET_BOT_LEARNING_ITEMS_META, {
        total_count,
        currentPage: data.page,
      });
    } finally {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingItems: false });
    }
  },

  createItem: async function createItem({ commit }, data) {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingItem: true });
    try {
      await BotLearningItemAPI.create(data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingItem: false });
    }
  },

  editItem: async ({ commit }, data) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingItem: true });
    try {
      const response = await BotLearningItemAPI.update(data.item_id, data);
      commit(types.UPDATE_BOT_LEARNING_ITEM, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingItem: false });
    }
  },

  getItem: async ({ commit }, data) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await BotLearningItemAPI.getSingleItem(data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingItem: false });
    }
  },

  publishItems: async ({ commit }) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isPublishing: true });
    try {
      const { data } = await BotLearningDashboardAPI.publish();
      commit(types.SET_BOT_LEARNING_DASHBOARD, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isPublishing: false });
    }
  },
};
