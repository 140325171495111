export const BOT_LEARNING_ROUTES = {
  MAIN: {
    PATH: 'bot-learning',
    NAME: 'bot_learning',
  },
  TAB_ITEMS: {
    PATH: 'list',
    NAME: 'bot_learning_tab',
  },
};
