/* global axios */
import ApiClient from './ApiClient';

class BotLearningTabAPI extends ApiClient {
  constructor() {
    super('bot_learning_tabs', { apiVersion: 'v2', accountScoped: true });
  }

  get() {
    let requestURL = this.url;
    return axios.get(requestURL);
  }

  create(title) {
    return super.create(title);
  }

  delete(id) {
    return super.delete(id);
  }

  edit(id, title) {
    return axios.put(`${this.url}/${id}`, title);
  }
}

export default new BotLearningTabAPI();
