import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  meta: {
    count: 0,
    currentPage: 0,
  },
  uiFlags: {
    isFetchingTabs: false,
    isCreatingTab: false,
    isDeletingTab: false,

    isFetchingItems: false,
    isFetchingItem: false,
    isCreatingItem: false,

    isFetchingDashboard: false,

    isPublishing: false,
  },
  tabRecords: [],
  itemRecords: [],
  dashboard: {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
